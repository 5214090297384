import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Not Found</h2>
        <p>Not a Valid URL</p>
        <p>Go to the main pain by clicking the button below</p>
        <a href="/#" className="button primary">Home</a>
      </header>
    </article>
  </Layout>
);

export default IndexPage;
